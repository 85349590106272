<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <Confirm ref="confirm" :method="store"/>
      <transition name="slide">
        <form ref="frmNuevo" novalidate>
          <CCard class="border-warning">
            <CCardHeader>
              <strong class="text-primary">
                Registro de Alumno nuevo
              </strong>
              <div class="card-header-actions">
                <button @click="$refs.modalVideo.mostrarVideo('video-alumno-nuevo.mp4')"
                        class="btn btn-outline-info" title="Ver video de ayuda">
                  <font-awesome-icon icon="fas fa-question-circle" />
                  Ayuda
                </button>
              </div>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-6 col-md-3">
                  <CSelect
                      label="Facultad"
                      :value.sync="inscripcion.facultad_id"
                      :plain="true"
                      :options="facultades"
                      @change="inscripcion.carrera_id=''"
                      required="required"
                  >
                  </CSelect>
                </div>
                <div class="col-6 col-md-3">
                  <label for="alumnoCarrera">Carrera</label>
                  <select v-if="inscripcion.facultad_id" id="alumnoCarrera" required="required"
                          v-model="inscripcion.carrera_id" class="form-control" @change="actualizaMaterias($event)">
                    <option value="">:: SELECCIONAR ::</option>
                    <option v-for="option in carreras[inscripcion.facultad_id]" :value="option.value">{{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.codigo_alumno" label="Codigo de alumno" readonly="" type="text"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.gestion" label="Gestión de ingreso" readonly="" type="text"></CInput>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-3">
                  <CInput label="1er Apellido" type="text" v-model="inscripcion.apellido1" required="required"
                          @change="inscripcion.apellido1=inscripcion.apellido1.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput label="2do Apellido" type="text" v-model="inscripcion.apellido2"
                          @change="inscripcion.apellido2=inscripcion.apellido2.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput label="Nombre(s)" type="text" v-model="inscripcion.nombres" required="required"
                          @change="inscripcion.nombres=inscripcion.nombres.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CSelect
                      :options="generos"
                      :plain="true"
                      :value.sync="inscripcion.genero_id"
                      label="Género"
                      required="required"
                  >
                  </CSelect>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.fecha_nacimiento" :max='maxdate' label="Fecha de nacimiento"
                          min='1930-01-01' type="date" required="required"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CSelect
                      :options="nacionalidades"
                      :plain="true"
                      :value.sync="inscripcion.nacionalidad_id"
                      label="Nacionalidad"
                      required="required"
                  >
                  </CSelect>
                </div>
                <div class="col-6 col-md-3">
                  <label class="" title="Si el alumno es extrangero seleccionar ':: NINGUNO ::'"> Departamento
                    <font-awesome-icon icon="fas fa-question-circle" size="1x"/></label>
                  <CSelect
                      :options="departamentos"
                      :plain="true"
                      :value.sync="inscripcion.departamento"
                      required="required"
                  >
                  </CSelect>
                </div>
                <div class="col-6 col-md-3">
                  <label class="" title="Si el alumno es extrangero escribir: 'Departamento - Provincia'"> Provincia
                    <font-awesome-icon icon="fas fa-question-circle" size="1x"/></label>
                  <CInput v-model="inscripcion.provincia"
                           type="text" required="required"></CInput>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.documento_identificacion"
                          label="N° de CI/Pasaporte" type="text" required="required"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CSelect
                      :options="si_nos"
                      :plain="true"
                      :value.sync="inscripcion.traspaso_id"
                      label="Alumno de traspaso"
                      required="required"
                      @change="actualizaMaterias($event)"
                  >
                  </CSelect>
                </div>
                <div class="col-6 col-md-3">
                  <CSelect
                      :options="estados_civiles"
                      :plain="true"
                      :value.sync="inscripcion.estado_civil_id"
                      label="Estado civil"
                      required="required"
                  >
                  </CSelect>
                </div>
                <div class="col-6 col-md-3">
                  <CSelect
                      :options="si_nos"
                      :plain="true"
                      :value.sync="inscripcion.trabaja_id"
                      label="Trabaja?"
                      required="required"
                  >
                  </CSelect>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.direccion" label="Dirección" type="text" required="required"
                          @change="inscripcion.direccion=inscripcion.direccion.toUpperCase()"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.telefono" label="Teléfono/Celular"
                          type="text" required="required"></CInput>
                </div>
                <div class="col-6 col-md-3">
                  <CInput v-model="inscripcion.email" label="email" type="email"></CInput>
                </div>

              </div>
              <hr/>
              <div class="row">
                <div class="col">
                  <label class="form-label" for="gestion">Gestión:</label>
                  <select class="form-control pl-1 pr-1" id="gestion"
                          v-model="inscripcion.gestion" required="required"
                          >
                    <option value="" selected>:: SELECCIONAR ::</option>
                    <template v-for="(option,key) in gestiones">
                      <optgroup :label="key">
                        <option v-for="suboption in option" :value="suboption.value">
                          {{ suboption.label }}
                        </option>
                      </optgroup>
                    </template>
                  </select>
                </div>
                <div class="col text-center">
                  <CSelect
                      :options="planes"
                      :plain="true"
                      :value.sync="inscripcion.plan_id"
                      label="Plan de Estudios"
                      required="required"
                  >
                  </CSelect>
                </div>
                <div class="col"></div>
              </div>
              <template v-if="mostrarMaterias">
                <div class="row">
                  <div v-for="(semestre, index) in materias" class="col-6">
                    <div class="card border-info">
                      <div class="card-header"><strong>Semestre {{ index }}</strong>
                        <div class=" card-header-actions">
                          <CSelect
                              :options="grupos[index]"
                              :plain="true"
                              :value.sync="grupo_semestre[index]"
                              @change="actualizarGrupo(index)"
                              label=""
                          />
                        </div>
                      </div>
                      <div class="card-body p-0">
                        <table class="table table-sm table-striped m-0">
                          <thead>
                          <tr>
                            <th width="6%"></th>
                            <th width="20%"><span>Sigla</span></th>
                            <th width="46%"><span>Materia</span></th>
                            <th width="34%"><span>Grupo</span></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(materia, index2) in semestre">
                            <td>
                              <input :value="materia.id"
                                     :checked="inscripcion.materias.hasOwnProperty(materia.id) && inscripcion.materias[materia.id]!=materiaPorDefecto"
                                     class="form-control" name="materia[]" type="checkbox"
                                     @change="deshabilitarMateria($event,materia.id)"
                              />
                            </td>
                            <td>{{ materia.sigla }}</td>
                            <td>{{ materia.materia }}</td>
                            <td>
                              <CSelect
                                  :options="materia.grupos"
                                  :plain="true"
                                  :value.sync="inscripcion.materias[materia.id]"
                                  @change="verificarMateria(materia.id)"
                                  label=""
                              />
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div v-if="this.materias.length<1" class="row">
                <div class="col  text-center text-danger">
                  <strong>No existen materias para mostrar</strong>
                </div>
              </div>
            </CCardBody>
            <CCardFooter>
              <CButton class="mr-2" color="primary" @click="store()">Grabar</CButton>
              <CButton color="danger" @click="goBack">Regresar a la pantalla anterior</CButton>
            </CCardFooter>
          </CCard>
        </form>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalVideo from "@/views/notifications/ModalVideo";
import Confirm from "@/views/notifications/Confirm";

var moment = require('moment');
var modalPdf = null;

export default {
  name: 'EditUser',
  components: {Confirm, ModalVideo, Alerts, Toast},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      inscripcion: {
        facultad_id: 0,
        carrera_id: 0,
        plan_id: 0,
        codigo_alumno: '',
        gestion: '',
        apellido1: '',
        apellido2: '',
        nombres: '',
        genero_id: 0,
        fecha_nacimiento: '',
        nacionalidad_id: 0,
        departamento: '',
        provincia: '',
        documento_identificacion: '',
        traspaso_id: 0,
        estado_civil_id: 0,
        trabaja_id: 0,
        direccion: '',
        telefono: '',
        email: '',
        casilla: '0',
        sede_id: localStorage.sedeSeleccionada,
        materias: [],
      },
      departamentos: [':: NINGUNO ::','La Paz', 'Beni', 'Cochabamba', 'Chuquisaca', 'Oruro', 'Pando', 'Potosi', 'Santa Cruz', 'Tarija'],
      planes: [], materiaPorDefecto: ":: SELECCIONAR ::",
      materias: [], gestiones: [],
      grupos: [], grupo_semestre: [],
      facultades: [], carreras: [],
      generos: [], estados_civiles: [],
      nacionalidades: [], si_nos: [],
      maxdate: moment(new Date()).add(-12 * 10, 'M').format("YYYY-MM-DD"),
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    store() {
      if (!modalPdf.$refs.frmNuevo.checkValidity()) {
        modalPdf.$refs.frmNuevo.classList.add('was-validated');
        modalPdf.$refs.mensajeToast.makeToast('', 'Algunos datos son requeridos.', 'danger');
      } else {
        modalPdf.$refs.alert.show('Descargando documento');
        axios.post(this.$apiAdress + '/api/inscripcion/store?token=' + localStorage.getItem("api_token"),
            modalPdf.inscripcion
        )
            .then(function (response) {
              modalPdf.$refs.alert.hide();
              modalPdf.inscripcion = {
                facultad_id: 0,
                carrera_id: 0,
                plan_id: 0,
                codigo_alumno: '',
                gestion: '',
                apellido1: '',
                apellido2: '',
                nombres: '',
                genero_id: 0,
                fecha_nacimiento: '',
                nacionalidad_id: 0,
                departamento: '',
                provincia: '',
                documento_identificacion: '',
                traspaso_id: 0,
                estado_civil_id: 0,
                trabaja_id: 0,
                direccion: '',
                telefono: '',
                email: '',
                casilla: '',
                sede_id: localStorage.sedeSeleccionada,
                materias: [],
                planes: [],
              };
              modalPdf.$refs.mensajeToast.makeToast('', 'Registro creado satisfactoriamente.', 'success');
              modalPdf.$router.push({path: '/inscripcion/alumnos'});
            })
            .catch(function (error) {
              modalPdf.$refs.alert.hide();
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
    actualizarGrupo(semestre) {
      for (let pos in this.materias[semestre]) {
        this.inscripcion.materias[this.materias[semestre][pos].id] = this.grupo_semestre[semestre];
        if (this.grupo_semestre[semestre] == modalPdf.materiaPorDefecto) {
          delete this.inscripcion.materias[this.materias[semestre][pos].id];
        }
      }
    },
    verificarMateria(materia_id) {
      if (modalPdf.inscripcion.materias[materia_id] == modalPdf.materiaPorDefecto) {
        delete modalPdf.inscripcion.materias[materia_id];
      }
    },
    deshabilitarMateria(event, materia_id) {
      if (!event.target.checked) {
        modalPdf.inscripcion.materias[materia_id] = modalPdf.materiaPorDefecto;
        delete modalPdf.inscripcion.materias[materia_id];
      }
    },
    actualizaMaterias(event) {
      if (modalPdf.inscripcion.plan_id > 0 && modalPdf.inscripcion.carrera_id > 0) {
        axios.get(this.$apiAdress + '/api/inscripcion/' + modalPdf.inscripcion.plan_id + '/' + modalPdf.inscripcion.carrera_id + '/' + (modalPdf.inscripcion.traspaso_id == 18 ? "0" : "1") + '/0/materias?token=' + localStorage.getItem("api_token"))
            .then(function (response) {
              if (modalPdf.inscripcion.traspaso_id != 18) {
                modalPdf.inscripcion.materias=[];
                for (let key in response.data.materias) {
                  for (let semestre in response.data.materias[key]) {
                    //modalPdf.inscripcion.materias.push(response.data.materias[key][semestre].id);
                    modalPdf.inscripcion.materias[response.data.materias[key][semestre].id] = response.data.materias[key][semestre].grupos[1];
                  }
                }
              }
              modalPdf.materias = response.data.materias;
              modalPdf.grupos = response.data.grupos;
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
  },
  computed: {
    mostrarMaterias() {
      if (this.inscripcion.plan_id !== '' && this.inscripcion.plan_id > 0) {
        this.actualizaMaterias();
        return true;
      }
      this.materias = [];
      return false;
    }
  },
  mounted: function () {
    modalPdf = this;
    modalPdf.inscripcion.sede_id = localStorage.sedeSeleccionada;
    axios.get(this.$apiAdress + '/api/inscripcion/' + localStorage.sedeSeleccionada + '/create?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.facultades = valorPorDefecto.concat(response.data.facultades);
          modalPdf.carreras = response.data.carreras;
          modalPdf.generos = valorPorDefecto.concat(response.data.generos);
          modalPdf.estados_civiles = valorPorDefecto.concat(response.data.estados_civiles);
          modalPdf.nacionalidades = valorPorDefecto.concat(response.data.nacionalidades);
          modalPdf.si_nos = valorPorDefecto.concat(response.data.si_nos);
          modalPdf.planes = valorPorDefecto.concat(response.data.planes);
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
    axios.post(this.$apiAdress + '/api/factura/' + localStorage.sedeSeleccionada + '/opcionesfactura?token=' + localStorage.getItem("api_token"),
        {'tipo_lista':'inscripcion_nuevo'}
    )
        .then(function (response) {
          modalPdf.gestiones = response.data.gestiones;
          modalPdf.inscripcion.gestion = response.data.mesActual;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  }
}

</script>